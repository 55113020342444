<!-- Localized -->
<template>
    <div>
        <div class="flex mb-4 justify-start items-center">
            <h2 class="table_label "> {{ $t('components.light_ray.company_domains_n', { value: his.totalDomianData.length || 0 }) }}</h2>
            <div class="flex ">
                <input type="checkbox" class="skipCheckbox" id="check" name="skipCheck" v-model="skipCheck"
                    @click="handleSkip" />
                <p class="font-small text-sm mt-2 faded">{{ $t('components.light_ray.the_required_company_domain_is_missing_in_the_list_below') }}</p>
            </div>
        </div>
        <vue-good-table theme="polar-bear" styleClass="vgt-table bordered" height="60vh" :rows="rowsDetailData"
            :isLoading="loadingData" :columns="domainColumnsList" :fixed-header="true" :style="this.center"  :select-options="{ enabled:  !this.skipCheck}" ref="goodTable" @on-selected-rows-change="setSelectedRow($event)"
            :class="{ 'disabled': skipCheck }" :pagination-options="{ enabled: true, mode: 'records', perPage: 5}">
            <!-- <template slot="table-row" slot-scope="props">
                <div v-if="props.column.field == 'selection'">
                    <div style="text-align: center;">
                        <input type="radio" class="radio_button" @click="setSelectedRow(props, 'company_domain')"
                            name="row-selection" :disabled="skipCheck" :checked="props.row.selected" />
                    </div>
                </div>
            </template> -->
        </vue-good-table>
        <div class="flex justify-between mt-2">
            <Button @click="goBack()" :outline="true" :text="$t('actions.back')" class="button_red_outline"></Button>
            <Button @click="goNext()" :outline="true" :text="$t('actions.next')" class="button_red"></Button>
        </div>
    </div>
</template>
<script>

import { getDomainDetails } from "../search-records/services"
import Button from "../../../components/button";
import { getSearchedDomainRecords } from "../../services";

export default {
    name: "searched-records",
    data: function () {
        return {
            domainColumns: [
            //     {
            //     label: "Selection",
            //     field: "selection",
            //     sortable: false,
            //     width: '100px',

            // },
            {
                label: "Domain",
                field: "domain",
                tKey: "domain", 
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane',
                    filterDropdownItems: ['a', 'v'],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            },
            {
                label: "Company Name",
                field: "name",
                tKey: "company_name", 
                width: "300px",
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane',
                    filterDropdownItems: [],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            },
            {
                label: "Description",
                field: "description",
                tKey: "description", 
                filterOptions: {
                    styleClass: 'class1',
                    enabled: true,
                    placeholder: 'Select',
                    // filterValue: 'Jane',
                    filterDropdownItems: ['a', 'v'],
                    // filterFn: this.columnFilterFn, 
                    trigger: 'enter',
                },
            }],
            rowsDetailData: null,
            loadingData: true,
            skipCheck: false,
            center: { margin: 'auto', width: '100%' },
            domainPagination: { page: 0, pageSize: 10 },
            selectedDomainRow: null,
            totalDomianData: [],
        }
    },
    components: {
        Button
    },
    async mounted() {
        await this.getDomainData()
        // const goodTable = this.$refs.goodTable.$el;
        // goodTable.querySelector('.vgt-responsive').addEventListener('scroll', this.handleScroll);
    },
    computed: {
        searchedQuery() {
            return this.$route.query
        },
        domainColumnsList() {
            return this.domainColumns.map(i => ({
                ...i,
                label: this.$t(`components.light_ray.${i.tKey}`)
            }))
        },
    },
    methods: {
        async handleScroll(event) {
            const { scrollTop, scrollHeight, clientHeight } = event.target;
            if (scrollHeight - scrollTop <= clientHeight) {
                let { page } = this.domainPagination;
                page = page + 1;
                let temp = [...this.totalDomianData]
                let x = temp.slice((page) * this.domainPagination.pageSize, (page + 1) * this.domainPagination.pageSize);
                let promises = x.map(item => {
                    return getDomainDetails({ domain: item.domain })
                })
                const descriptionData = await Promise.allSettled(promises)
                descriptionData.map((item, i) => {
                    if (item.status === "fulfilled") {
                        const description = item?.value?.data?.data?.description;
                        x[i].description = description || ' ';
                    }
                })
                this.rowsDetailData = [...this.rowsDetailData, ...x];
                this.domainPagination.page = page;
            }

        },
        async getDomainData() {
            try {
                let domainPayload = { query: this.searchedQuery.name }
                let companyDomainData = await getSearchedDomainRecords(domainPayload);
                this.totalDomianData = companyDomainData.data?.data;
                if(!this.totalDomianData){
                    this.totalDomianData=[];
                    this.skipCheck=true
                }
                // this.rowsDetailData = this.totalDomianData.slice(0, 10);
                this.rowsDetailData =   this.totalDomianData
                let promises = this.rowsDetailData.map(item => {
                    return getDomainDetails({ domain: item.domain })
                })
                const descriptionData = await Promise.allSettled(promises)
                descriptionData.map((item, i) => {
                    if (item.status === "fulfilled") {
                        const description = item?.value?.data?.data?.description;
                        this.rowsDetailData[i].description = description || ' ';
                    }
                })

                // Add filter details
                this.domainColumns[0].filterOptions.filterDropdownItems = this.rowsDetailData.map(item => item.domain)
                this.domainColumns[1].filterOptions.filterDropdownItems = this.rowsDetailData.map(item => item.name)
                this.domainColumns[2].filterOptions.filterDropdownItems = this.rowsDetailData.map(item => ({value:item.description , text: `${item.description.substr(0,150)}...`}))
                this.rowsDetailData = JSON.parse(JSON.stringify(this.rowsDetailData))
                this.loadingData = false;
            }
            catch (err) {
                console.error('err', err)
                this.loadingData =false;
                this.$toast.error(err.response.data.detail)
                
            }
        },
        setSelectedRow(data) {
                this.selectedDomainRow = data.selectedRows  
        },
        handleSkip(e) {
            if (e.target.name == "skipCheck") {
                this.skipCheck = !this.skipCheck
            }
        },
        goBack() {
            this.$router.push({
                name: "search-company-records",
                params: { type: "company-search" }, query: {
                    name: this.searchedQuery.name,
                    jurisdiction: this.searchedQuery.jurisdiction,
                    case_id: this.searchedQuery.case_id,
                    neo_uuid: this.searchedQuery.neo_uuid,
                }
            })
        },
        goNext() {
            if (this.skipCheck || this.selectedDomainRow?.length) {
                this.$router.push({
                    name: "company-records",
                    params: { type: "company-search" },
                    query: {
                        name: this.searchedQuery.name,
                        jurisdiction: this.searchedQuery.jurisdiction,
                        case_id: this.searchedQuery.case_id,
                        neo_uuid: this.searchedQuery.neo_uuid,
                        skip: this.skipCheck
                    }
                })
            }
            else {
                this.$toast.error(this.$t('components.light_ray.please_select_at_least_one_entity'))
            }
        },
    }

}
</script>
<style scoped lang="scss">
@import "../../../assets/mixins/mixins.scss";
@import "../../assets/scss/color.scss";

.table_label {
    color: #263238;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.skipCheckbox {
    margin-right: 4px;
    margin-left: 12px;
    margin-top: 5px;
}

::v-deep {

    .button_red_outline {
        padding: 14px 60px;
        border-color: $lightray_product_color;
        border-radius: 6px;
        color: $lightray_product_color;
    }

    .button_red_outline:hover {
        border-color: $lightray_product_color;
        background-color: $lightray_product_color;
        color: white;
    }

    .button_red {
        padding: 14px 60px;
        border-color: $lightray_product_color;
        background-color: $lightray_product_color;
        color: white;
    }

    .vgt-wrap__footer {
        padding: 0.5rem 0.75rem !important;
        font-size: 10px !important;
        font-weight: 300 !important;

        label,
        button,
        select {
            font-size: 0.75rem !important;
            font-weight: 500 !important;
        }
    }

    .vgt-table {
        font-size: 10px !important;
        border-radius: 0.25rem !important;
        border: 1px solid #d9d9d9 !important;
    }

    .vgt-table tbody {
        font-size: 10px !important;
        font-weight: bold;
    }

    .vgt-responsive {
        border-radius: 0.25rem;
        border-bottom: 0.15rem solid #d9d9d9 !important;
    }

    .vgt-responsive::-webkit-scrollbar {
        width: 5px !important;
    }

    .vgt-table th {
        background: #F6F8FA !important;
        color: #686A73 !important;
        font-family: Poppins !important;
        font-size: 10px !important;
        font-style: normal !important;
        font-weight: 400 !important;
        line-height: 14px !important;
        letter-spacing: 0.2px !important;
    }

    .vgt-table th:first-child {
        text-align: center !important;
        vertical-align: middle !important;
    }

    .vgt-table .vgt-left-align {
        text-align: left !important;
        vertical-align: middle !important;
    }

    .vgt-table .vgt-left-align input[type="radio"]:checked {
        accent-color: var(--theme-color-main) !important;
    }

    .vgt-table .vgt-checkbox-col input[type="checkbox"]:checked {
        accent-color: var(--theme-color-main) !important;
        background: var(--theme-color-main) !important;
        ;
    }

    .disabled .vgt-table {
        overflow: hidden !important;
        cursor: not-allowed !important;

        // opacity: 0.5 !important;
        span {
            color: #888 !important;
        }
    }

    th.filter-th div select.vgt-select {
        border-radius: 10px !important;
    }
}</style>