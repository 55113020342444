<!-- Localized -->
<template>
  <div>
    <div>
      <div class="tabs ">
        <a v-for="(tab, index) in this.allSelectedCompany" :key="index" class="tab tab-lifted"
          :class="{ 'tab-active': activeTabIndex === index }" @click="activateTab(index)">
          <span class="" :title="tab?.name">{{ tab?.name }}</span>
          <font-awesome-icon @click="handleModalCompanyDetails(tab,index); $event.stopPropagation()"
                            class="cursor-pointer ml-2" style="font-size: 0.875rem;"
                            icon="info-circle" />
        </a>
      </div>
      <div class="" v-if="searchedQuery.skip==true">
        <SelectEntities :officerRows="[]"
          :ownershipRows="[]"
          :previousNamesRows="[]" />
      </div>
      <div class="" v-else>
        <SelectEntities  :officerRows="this.tableData?.officersData"
          :ownershipRows="this.tableData?.ownershipData"
          :previousNamesRows="this.tableData.previousData"  :loader="loader" />
      </div>
    </div>
    <div class="flex justify-between mt-4 ">
      <div class="">
        <Button @click="goBack()" :text="$t('actions.back')" class="button_red_outline" :outline="true"></Button>
      </div>
      <div class="">
        <Button @click="searchedQuery.skip == 'true' ? skipRecordsDetails() : saveSelectedEntities()" ghost :text="$t('actions.next')"
          class="button_red"></Button>
      </div>
    </div>
    <!--Company details modal-->
    <div>
         <modal-content ref="company-records" :title="$t('components.light_ray.company_details')" name="records" :showFooter="false">
                <template #content>
                    <companyDetails :jurisdictionData="jurisdictionData" :companyDetails="modalData"
                        :showPrintButton="true" />
                </template>
        </modal-content>
    </div>
  </div>
</template>
  
<script>
// import store from "../../../../tenant-app/src/store"
import modalContent from "../../../../tenant-app/src/components/modal-content"
import Button from "../../../components/button"
import { makeCompanyGraph, mergeAllNodeWithCentralnode, onSearch , } from "../../components/graph/helpers";
import { insertGraphNodes , graphParentRelationship ,saveToolGraphData } from "../../services";
import { getCompanyDetails, getOwnershiDetails, updateEntity,} from "../search-records/services";
import SelectEntities from "./select-table";
import companyDetails from "../../components/companyDetails"
export default {
  components: {
    Button,
    SelectEntities,
    "modal-content": modalContent,
    companyDetails
  },
  data() {
    return {
      activeTabIndex: 0, // Set the initially active tab index
      jurisdictionData: [],
      selectedCompanyData: {},
      skipCheck: false,
      selectedData: {},
      allSelectedCompany: [],
      allData: {},
      selectEntityData: {},
      loader:true,
      tableData:{},
      companyDetails:[],
      modalData:{},
    };
  },
  computed: {
    searchedQuery() {
      return this.$route.query
    },
    currentPage() {
      return this.$route.params.type
    },
  },
  methods: {
    activateTab(index) {
      this.activeTabIndex = index;
      const selectedCompany = this.allSelectedCompany[this.activeTabIndex];
      this.tableData = this.allData[selectedCompany.company_number];
    },
    handleModalCompanyDetails(tab,index){
        this.modalData = this.companyDetails[index];
         this.$refs["company-records"].showModal();
    },
    goBack() {
      this.$router.push({
        name: "company-records",
        params: { type: "company-search" },
        query: {
          name: this.searchedQuery.name,
          jurisdiction: this.searchedQuery.jurisdiction,
          case_id: this.searchedQuery.case_id,
          neo_uuid: this.searchedQuery.neo_uuid,
        }
      })
    },

    async saveSelectedEntities() {
      if(!this.searchedQuery.skip){
      let payload = [];
      let data = localStorage.getItem("SelectedCompany");
      data = JSON.parse(data);
      let requestId = data?.requestId;
      let selected = {};
      for (let i = 0; i < data?.selectedCompany.length; ++i) {
        payload.push(
          {
            name: data?.selectedCompany[i].name,
            type: "company",
            main_entity: true,
            entity_meta_data: {
              code: data?.selectedCompany[i]?.jurisdiction_code,
              doc_id: data?.selectedCompany[i]?.doc_id,
              company_number: data?.selectedCompany[i]?.company_number,
              query_id: requestId,
            },
            jurisdiction:{name:data?.selectedCompany[i]?.jurisdiction,code:data?.selectedCompany[i]?.jurisdiction_code},
            parent_entity_key : data?.selectedCompany[i]?.company_number,
          }
        )
        selected[data?.selectedCompany[i]?.company_number] = {
                     "node_label": data?.selectedCompany[i].name,
                     "root_node": true,
                     "entity_type": "company",
                     "node_status": "confirmed",
                     'node_type': 'company'
        }
      }
      let graphPayload =  {
             "case_id": this.searchedQuery.case_id,
             "nodes_relationship": [ ]
      }   
      for (const property in this.allData) {
        let data = { ...selected[property],
                     connected_nodes:[]
                   }
        this.allData[property].officersData.forEach(item => { 
          if (item?.vgtSelected) {
            payload.push({ name: `${item.name}`, type: "person", main_entity: true ,parent_entity_key : property}) 
            data.connected_nodes.push({
                     "node_label": item.name,
                     "root_node": false,
                     "entity_type": "person",
                     "node_status": "confirmed",
                     'node_type': 'person'
              })
          }
        })
        this.allData[property].ownershipData.forEach(item => { 
          if (item?.vgtSelected){
            payload.push({ name: `${item.name}`, parent_entity_key : property , type: `${item.type == 'entityStatement' ? 'company' : 'person'}`, main_entity: true,jurisdiction:item.type=='entityStatement'? item?.jurisdiction[0]:{name:"",code:""} }) 
            data.connected_nodes.push({
                     "node_label": item.name,
                     "root_node": false,
                     "entity_type": item.type == 'entityStatement' ? 'company' : 'person',
                     "node_status": "confirmed",
                     'node_type': item.type == 'entityStatement' ? 'company' : 'person'
              })
          }
        })
        this.allData[property].previousData.forEach(item => { 
          if (item?.vgtSelected){
            payload.push({ name: `${item.company_name}`, type: "company", main_entity: true , parent_entity_key : property}) 
            data.connected_nodes.push({
                     "node_label": item.company_name,
                     "root_node": false,
                     "entity_type": "company",
                     "node_status": "confirmed",
                     'node_type': 'company',
              })
          }
        })
         
        
        graphPayload.nodes_relationship.push(data)
      }
      await updateEntity(payload, this.$route.query.neo_uuid);
      await graphParentRelationship(graphPayload);
      let juri = data?.jurisdictionData
      let juriConvert = {}
      juri.forEach((i)=> ( juriConvert[i.value] =  i.label ))
      
     
      for (let i = 0; i < this.companyDetails.length; ++i) {
        
       let graph = {
          "tool_data": [
              {
                  "detail": {
                      "company": {
                          ...this.companyDetails[i]?.company,
                      }
                  },
                  "company": {
                      "company_number": this.companyDetails[i]?.company?.company_number,
                      "name": this.companyDetails[i]?.company?.name,
                      "incorporation_date": this.companyDetails[i]?.company?.incorporation_date,
                      "current_status": this.companyDetails[i]?.company?.current_status,
                      "jurisdiction_code": this.companyDetails[i]?.company?.jurisdiction_code,
                      "registered_address_in_full": this.companyDetails[i]?.company?.registered_address_in_full
                  },
                  "card_name": this.companyDetails[i]?.company?.name,
                  
              }
          ],
          "extra_fields": {
              "search_string": this.companyDetails[i]?.company?.name,
              "query_data": {},
              "source": "opencorporates",
              "jurisdiction": juriConvert
          },
          "case_id": this.searchedQuery.case_id
       } 
        saveToolGraphData(graph);
      }
      this.$router.push({
            name: "select-features",
            query: {
              package_id: this.$route.query.package_id,
              client_id: this.$route.query.client_id,
              t: new Date().getTime(),
              mode: "features",
            },
          });
      }
      else{
        this.skipRecordsDetails();
      }
    },
    async skipRecordsDetails() {
      let payload = [{
        name: this.$route.query.name,
        type: "company",
        main_entity: true,
      }]
      await updateEntity(payload, this.$route.query.neo_uuid);
      this.$router.push({
            name: "select-features",
            query: {
              package_id: this.$route.query.package_id,
              client_id: this.$route.query.client_id,
              t: new Date().getTime(),
              mode: "features",
            },
          });

    }
  },
  async mounted() {
    let data = localStorage.getItem("SelectedCompany");
    data = JSON.parse(data);
    this.jurisdictionData = data.jurisdictionData;
    if (data) {
      this.allSelectedCompany = data?.selectedCompany
      let allCompanyData = data?.allCompanyData;
      let requestId = data?.requestId;
      for (let i = 0; i < data?.selectedCompany.length; ++i) {
        const payload = {
          card_type: "company",
          code: this.allSelectedCompany[i]?.jurisdiction_code,
          doc_id: this.allSelectedCompany[i]?.doc_id,
          number: this.allSelectedCompany[i]?.company_number,
          query_id: requestId,
        }
        const selectedCompanyData = allCompanyData.filter(res => res.company.company_number == this.allSelectedCompany[i].company_number);
        this.selectedCompanyData = selectedCompanyData[0];
        let companyDetails = await getCompanyDetails(payload);
        this.companyDetails[i] = companyDetails?.data?.data;
        const newPayload = { comp_id: this.allSelectedCompany[i]?.company_number };
        let owner = await getOwnershiDetails(newPayload);
        let uniqueKey = `Lightray__${(this.searchedQuery.name)}__`;
        const companyData = [{ ...this.selectedCompanyData, "chain_data": owner?.data, "company_details": companyDetails?.data?.data }];
        const graphData = makeCompanyGraph({ data: companyData }, uniqueKey);
        let centralNodepayload = { "data": { "company_name": this.searchedQuery.name }, "source": "company" }
        let centralNodes = await onSearch(centralNodepayload, uniqueKey)
        let resultGraphData = mergeAllNodeWithCentralnode(graphData, centralNodes)
        let case_id = sessionStorage.getItem("neo_case_id");
        await insertGraphNodes({ case_id, "nodes": resultGraphData.nodes, "edges": resultGraphData.links });
        companyDetails = companyDetails?.data?.data
        let ownershipData = owner.data.entity;
        let officersData = companyDetails.company?.officers.map(res => res.officer);
        let previousData = companyDetails.company?.previous_names;
        this.allData[this.allSelectedCompany[i].company_number] = { ownershipData, officersData, previousData}
      }
      this.activateTab(0)
      this.loader=false
    }
  }
};
</script>
<style lang="scss" scoped>
@import "../../assets/scss/color.scss";

.button_red_outline {
  border-color: $lightray_product_color;
  border-radius: 6px;
  color: $lightray_product_color;
  padding: 14px 60px
}

.tab-active {
  background: $lightray_product_color !important;
  color: white !important;
}

.tab-lifted.tab-active::after,
.tab-lifted.tab-active::before {
  --tab-corner-bg: "none" !important;
}

.button_red_outline:hover {
  border-color: $lightray_product_color;
  background-color: $lightray_product_color;
  color: white;

}

.button_red {
  border-color: $lightray_product_color;
  background-color: $lightray_product_color;
  color: white;
  padding: 14px 60px
}</style> 